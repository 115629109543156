import React from 'react';
import ProfilePic from '../../assets/profile.jpg';
import FadeIn from 'react-fade-in';
import BookList from '../about/BookList';

const About = () => {
  return (
    <FadeIn>
      <div className="about-page mt-4">
        <div className="container">
        <h1 className="display-5 section-header">Who am I?</h1>
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <img src={ProfilePic} className="img-fluid rounded-img" alt="Portrait of Brendan Noble"/>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="card p-4 mt-lg-0 mt-4">
              <h4 className="section-header">Hello, my name is Brendan!</h4>
              <p className="double-space">I am a student passionate about technology and design attending Weber State University. I am currently studying Computer Science and minoring in Entrepreneurship. I live in the greater Salt Lake City area along the beautiful Wasatch mountains. I enjoy tinkering with technology, taking photos, and web design.</p>
            </div>
          </div>
        </div>
        <section id="things-i-enjoy">
          <h1 className="display-5 section-header mt-5">Things I enjoy</h1>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="card p-4 text-center">
                <i className="fas fa-code fa-5x"></i>
                <h4 className="section-header">Programming</h4>
                <p className="text-left double-space">I enjoy working with various languages and different projects. Currently I am interested in front-end web development and mobile development. I am familiar with Java, C++, Javascript, HTML, CSS, ReactJS, and React Native.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card p-4 text-center mt-lg-0 mt-4">
                <i className="fas fa-camera fa-5x"></i>
                <h4 className="section-header">Photography</h4>
                <p className="text-left double-space">One of my favorite pastimes is taking photos. Some of my favorite locations include, the mountains near my home, the Uintahs, California, Idaho, and Nevada. I most enjoy taking photos of mountain landscapes, sunsets, and lake scenes. </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card p-4 text-center mt-lg-0 mt-4">
                <i className="fas fa-hiking fa-5x"></i>
                <h4 className="section-header">Health & Fitness</h4>
                <p className="text-left double-space">I love anytime I can get outdoors with friends to go hiking, camping, and exploring. I enjoy lifting weights, bike riding, and yoga. I also enjoy mindfulness meditation and contemplating the universe.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="what-i-am-learning" className="mb-5">
          <h1 className="display-5 section-header mt-5">What I'm reading</h1>
          <div className="row">
            <BookList />
          </div>
        </section>
        </div>
      </div>
    </FadeIn>
  )
}

export default About;
