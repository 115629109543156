import React from 'react'

const Footer = () => {
  return (
    <footer className="container-fluid">
      <p className="text-center">© 2020 Brendan Noble</p>
    </footer>
  )
}

export default Footer
