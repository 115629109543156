import React, { useContext } from 'react';
import { ProjectsContext } from '../../contexts/ProjectsContext';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { v1 as uuidv1 } from 'uuid';

const Projects = () => {

  const { projects } = useContext(ProjectsContext);

  return (
    <section className="projects-page mt-4">
      <div className="container">
      <FadeIn>
        <div className="row">
          {
            projects.map(project => (
              <div className="col-lg-6 col-sm-12"  key={uuidv1()}>
                <div className="card mb-4">
                  <div className="card-header">
                  <h3 className="card-title display-4 font-weight-bold text-center">{project.title}</h3>
                  </div>
                  <img src={project.img} class="card-img" alt={project.imgAlt}></img>
                  <div className="card-body">
                    <p className="card-text">{project.desc}</p>
                    <Link className="btn btn-primary" to={project.link}>{project.linkText}</Link>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </FadeIn>
      </div>
    </section>
  )
}

export default Projects;
