import React from 'react';
import Navbar from './components/layout/Navbar';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './components/page/Home'
import Footer from './components/layout/Footer';
import Blog from './components/page/Blog';
import Projects from './components/page/Projects';
import Contact from './components/page/Contact';
import PostDetails from './components/blog/PostDetails'
import PostsContextProvider from './contexts/PostsContext';
import ProjectsContextProvider from './contexts/ProjectsContext';
import About from './components/page/About';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <PostsContextProvider>
          <ProjectsContextProvider>
            <Navbar/>
            <Switch>
              <Route exact path='/' component={Home}/>
              <Route path='/blog' component={Blog}/>
              <Route path='/post/:id' component={PostDetails}/>
              <Route path='/projects' component={Projects}/>
              <Route path='/contact' component={Contact}/>
              <Route path='/about' component={About}/>
            </Switch>
            <Footer/>
          </ProjectsContextProvider>
        </PostsContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
