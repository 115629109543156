import React, { useContext } from 'react';
import { PostsContext } from '../../contexts/PostsContext';
import moment from 'moment'

const PostDetails = (props) => {

  const { posts } = useContext(PostsContext);
  const id = props.match.params.id;

  const post = posts ? posts[id-1] : null;

  return (
    <div className="container">
      <div className="card mt-4">
      <div className="card-header">{moment(Date()).calendar()}</div>
      <div className="card-body">
        <h1 className="card-title text-center">{post.title}</h1>
        <div className="card-text">{post.content}</div>
      </div>
      </div>
    </div>
  )
}

export default PostDetails
