import React from 'react'
import { Link } from 'react-router-dom'
import PostList from '../blog/PostList'
import FadeIn from 'react-fade-in'

const Blog = () => {
  return (
    <section className="blog-page mt-4">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-12">
            <FadeIn>
              <PostList />   
            </FadeIn>        
          </div>
          <div className="col-sm-4 col-0">
            <div className="card mb-4">
              <div className="card-header">Menu</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <Link>Recent</Link>
                </li>
                <li className="list-group-item">
                  <Link>Best</Link>
                </li>
                <li className="list-group-item">
                  <Link>Archived</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
