import React, { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import FadeIn from 'react-fade-in';

const BookList = () => {

  const [books, setBooks] = useState();

  useEffect(() => {
    
    let convert = require('xml-js');

    fetch('http://cors-anywhere.herokuapp.com/https://www.goodreads.com/review/list?key=DDTOMYkMIMEoBeEDJdgGGA&v=2&id=102994111&shelf=currently-reading')
      .then((response) => {
        if (response.status !== 200) {
          console.log("There was an error in your request: " + response.status);
        } else {
          return response.text();
        }
      }).then((data) => {
        let result = convert.xml2js(data, {compact: true, spaces: 4});
        let list = result.GoodreadsResponse.reviews.review;
        setBooks(list);
      });
  }, [])

  if (!books) {
    return (
      <div className="loading mx-auto my-4">
        <div className="spinner-border text-primary m-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    return ( 
      <div className="book-list">
       <FadeIn>
          <ul className="list-group card mx-3">
            {
              books.map((item) => {
                return (
                  <li key={uuidv1()} className="list-group-item"><a target="_blank" href={item.book.link._text}><strong>{item.book.title._text}</strong> <br/> by {item.book.authors.author.name._text}</a></li>
                );
              })
            }
          </ul>
          <p className="text-muted text-center mt-4">Pulled from <a className="text-muted" target="_blank" href="https://goodreads.com">Goodreads.com</a></p>
        </FadeIn>
      </div>
    );
  }
}

export default BookList;
