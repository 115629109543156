import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PostList from '../blog/PostList';
import Form from '../contact/Form';
import FadeIn from 'react-fade-in';
import { ProjectsContext } from '../../contexts/ProjectsContext';
import { v1 as uuidv1 } from 'uuid';

const Home = () => {

  const { projects } = useContext(ProjectsContext);

  return (
    <section className="home-page">
      <div className="jumbotron jumbotron-fluid">
        <FadeIn>
          <div className="container jumbo-card">
            <h1 className="display-3">Hey, there!</h1>
            <p className="lead mt-5">Welcome to my personal project page.</p>
            <Link className="btn btn-primary btn-lg" to="/projects">Projects</Link>
          </div>
        </FadeIn>
      </div>
      <FadeIn>
        <div className="container">
          <div className="showcase mb-4">
              <h1 className="display-5 section-header">Projects</h1>
              <div className="row">
                {
                  projects.map((project) => {
                    return (
                      <div className="col-md" key={uuidv1()}>
                      <div className="card mb-4">
                        <img src={project.img} alt="" className="card-img-top"/>
                        <div className="card-body">
                          <h4 className="card-title">{project.title}</h4>
                          <p className="card-text">{project.desc}</p>
                          <Link className="btn btn-primary" to={project.link}>{project.linkText}</Link>
                        </div>
                      </div>
                    </div>
                    );
                  })
                }
                </div>
          </div>
          <div className="latest-post mb-4">
            <h1 className="display-5 section-header">Latest post</h1>
            <PostList size={1} />
          </div>
          <div className="quick-contact mb-4">
          <h1 className="display-5 section-header">Send me a message</h1>
            <Form />
          </div>
        </div>
      </FadeIn>
    </section>
  )
}

export default Home
