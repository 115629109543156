import React from 'react';
import Form from '../contact/Form';
import FadeIn from 'react-fade-in';

const Contact = () => {
  return (
    <div className="contact-page mt-4">
      <div className="container">
        <section>
        <h1 className="display-5 section-header">Find me on social</h1>
        <FadeIn>
        <div className="row">
          <div className="col-lg-4 col-sm-12 text-center">
            <a href="https://github.com/brendannoble" className="text-decoration-none">
              <div className="social-card card p-4 mb-4">
                <h2>@brendannoble</h2>
                <i class="fab fa-github fa-w-16 fa-8x" aria-hidden="true"></i>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-sm-12 text-center">
            <a href="https://www.linkedin.com/in/brendan-noble/" className="text-decoration-none">
              <div className="social-card card p-4 mb-4">
                <h2>Brendan Noble</h2>
                <i class="fab fa-linkedin fa-w-16 fa-8x" aria-hidden="true"></i>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-sm-12 text-center">
            <a href="https://www.facebook.com/Brendan.J.Noble" className="text-decoration-none">
              <div className="social-card card p-4 mb-4">
                <h2>Brendan Noble</h2>
                <i class="fab fa-facebook fa-w-16 fa-8x" aria-hidden="true"></i>
              </div>
            </a>
          </div>
        </div>
        </FadeIn>
        </section>
        <section className="mb-5">
          <h1 className="display-5 section-header">Contact Me</h1>
          <Form />
        </section>
      </div>
    </div>
  )
}

export default Contact;
