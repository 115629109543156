import React, { useContext } from 'react';
import { PostsContext } from '../../contexts/PostsContext';
import { Link } from 'react-router-dom';
import moment from 'moment';

const PostList = ({size}) => {

  const { posts } = useContext(PostsContext);
  let slicedposts = posts;

  if (size !== null) {
    slicedposts = posts.slice(0, size);
  }

  return posts.length ? (
    slicedposts.map(post => {
      return (
        <Link className="post-card" to={'/post/' + post.id} key={post.id}>
          <div className="mb-4">
            <div className="postitem card">
              <div className="card-body">
                <h4 className="card-title">{post.title}</h4>
                <p className="card-text">{post.content}</p>
              </div>
              <div className="card-footer text-muted">{moment(Date()).calendar()}</div>
            </div>
          </div>
        </Link>
      )
    })
  ) : (
    <div>No posts :(</div>
  );
   
}

export default PostList
