import React, { createContext, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';

export const PostsContext = createContext();

const PostsContextProvider = (props) => {

  const [posts, setPosts] = useState([
    { title: 'Post 1', content: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, ex.', id: 1 },
    { title: 'Post 2', content: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, ex.', id: 2 },
    { title: 'Post 3', content: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, ex.', id: 3 },
    { title: 'Post 4', content: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia, ex.', id: 4 }
  ])

  return (
    <PostsContext.Provider value={{posts}}>
      {props.children}
    </PostsContext.Provider>
  )
}

export default PostsContextProvider;
