import React, { createContext, useState } from 'react';
import GalleryImg from '../assets/gallery_project.jpg';
import FlowtimeImg from '../assets/Flowtime.jpg';
import BookclubImg from '../assets/bookclub_project.jpg';
import { v1 as uuidv1 } from 'uuid';

export const ProjectsContext = createContext();

const ProjectsContextProvider = (props) => {

  const [projects, setProjects] = useState([
    { title: 'Book Club', desc: 'Book club suggestion site with neumorphic design elements', link: '/', linkText: 'View', img: BookclubImg, imgAlt: 'Screenshot of book club' },
    { title: 'Flowtime', desc: 'Productivity timer built for Android and iOS, soon to be launched on Google Play', link: '/', linkText: 'View', img: FlowtimeImg, imgAlt: 'Flowtime app advertisement' },
    { title: 'Photo Gallery', desc: 'Simple Javascript photogallery with elegant design', link: '/', linkText: 'View', img: GalleryImg, imgAlt: 'Screenshot of photo gallery' }
  ]);

  return (
    <ProjectsContext.Provider value={{projects}}>
      {props.children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsContextProvider;
