import React from 'react'

const Form = () => {

  const handleForm = (e) => {
    e.preventDefault();
  }

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleForm}>
          <div className="form-group">
            <label htmlFor="nameInput">Name</label>
            <input type="text" class="form-control" id="nameInput"/>
          </div>
          <div className="form-group">
            <label htmlFor="emailInput">Email Address</label>
            <input type="email" class="form-control" id="emailInput"/>
          </div>
          <div className="form-group">
            <label htmlFor="messageInput">Message</label>
            <textarea class="form-control" id="messageInput" rows="3"></textarea>
          </div>
          <button type="submit" className="btn btn-primary font-weight-bold" onClick={() => alert("Your message has been sent!")}><i class="fas fa-paper-plane" aria-hidden="true"></i> Submit</button>
        </form>
      </div>
    </div>
  )
}

export default Form
