import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

  const colUpdate = () => {
    document.getElementById('navbarNavAltMarkup')
      .classList.contains('show');
    document.getElementById('navbarNavAltMarkup')
      .classList.remove('show');
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container my-lg-2">
        <Link to="/" className="navbar-brand">BRENDAN NOBLE</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto font-weight-bold">
            <Link className="nav-item nav-link" to="/" onClick={colUpdate}>Home</Link>
            <Link className="nav-item nav-link" to="/blog" onClick={colUpdate}>Blog</Link>
            <Link className="nav-item nav-link" to="/projects" onClick={colUpdate}>Projects</Link>
            <Link className="nav-item nav-link" to="/about" onClick={colUpdate}>About</Link>
            <Link className="nav-item nav-link" to="/contact" onClick={colUpdate}>Contact</Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
